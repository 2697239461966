import React from "react"
import { FaTwitter, FaFacebook, FaEnvelope, FaLinkedin } from "react-icons/fa"
import { ShareButtonCircle, ShareBlockStandard } from "react-custom-share"

const ShareButtonComp = ({ text, longtext, id }) => {
  const shareBlockProps = {
    url: "https://localhost:8000/" + id.toString(),
    button: ShareButtonCircle,
    buttons: [
      { network: "Twitter", icon: FaTwitter },
      { network: "Facebook", icon: FaFacebook },
      { network: "Email", icon: FaEnvelope },
      { network: "Linkedin", icon: FaLinkedin },
    ],
    text: text + " -- ",
    longtext,
  }

  return (
    <div className="text-left">
      <ShareBlockStandard {...shareBlockProps} />
    </div>
  )
}

export default ShareButtonComp
