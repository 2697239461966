import React from "react"
import {
  IoLogoTwitter,
  IoLogoGithub,
  IoLogoLinkedin,
  IoIosMail,
  IoMdHeart,
} from "react-icons/io"

import Colors from "./../../Theme/theme"

const iconsArr = [
  {
    id: 1,
    icon: <IoLogoGithub size={40} />,
    link: "https://github.com/nishkaush",
    ariaLabel: "Check out my github",
  },
  {
    id: 2,
    icon: <IoLogoLinkedin size={40} />,
    link: "https://www.linkedin.com/in/nishantkaushish/",
    ariaLabel: "Connect with me on Linkedin",
  },
  {
    id: 3,
    icon: <IoLogoTwitter size={40} />,
    link: "https://twitter.com/Nishkaush",
    ariaLabel: "Connect with me on Twitter",
  },
]

const Footer = props => {
  return (
    <div className="row mt-5 py-5" style={{ backgroundColor: Colors.primary }}>
      <footer className="text-center col-12 text-white">
        <div className="col-10 col-sm-8 col-lg-5 py-5 mx-auto">
          <img
            src={require("./../../assets/my-logo-white.png")}
            alt="Nishant Kaushish Logo"
            style={{ width: "25%" }}
          />
          <p
            style={{
              fontSize: "1.3em",
              fontWeight: "100",
              paddingTop: "1em",
              letterSpacing: "1px",
            }}
          >
            Tinkering & Exploring the world one project at a time
          </p>
        </div>
        <div className="col-11 col-sm-8 col-md-6 col-lg-3 pb-5 d-flex justify-content-around mx-auto">
          {iconsArr.map(icon => (
            <a
              aria-label={icon.ariaLabel}
              role="button"
              key={icon.id}
              className="text-white"
              href={icon.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {icon.icon}
            </a>
          ))}
        </div>

        <small style={{ fontWeight: "100", letterSpacing: "1px" }}>
          Built by me © {new Date(Date.now()).getFullYear()}
        </small>
        <br />
        <small style={{ fontWeight: "100", letterSpacing: "1px" }}>
          Made with React & lots of <IoMdHeart size={15} />
        </small>
        <br />
        <br />
        <br />
      </footer>
    </div>
  )
}

export default Footer
