import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { MdKeyboardBackspace } from "react-icons/md"

import ShareButtonComp from "./../components/ShareButtonComp/ShareButtonComp"
import Footer from "./../components/Footer/Footer"
import BackToTopBtn from "./../components/BackToTopBtn/BackToTopBtn"
import Head from "./../components/Head/Head"
import Colors from "./../Theme/theme"
// import "./BlogPost.css"

export const query = graphql`
  query MyQuery($slug: String) {
    markdownRemark(id: { eq: $slug }) {
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        title
        description
        tags
      }
      html
      id
    }
  }
`

const BlogPost = props => {
  const [myPosition, setMyPosition] = useState(0)
  const handleScroll = () => setMyPosition(window.scrollY)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return function() {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [myPosition])

  const {
    title,
    date,
    description,
    tags,
  } = props.data.markdownRemark.frontmatter
  const { id } = props.data.markdownRemark

  return (
    <div className="container-fluid container-lg">
      <Head pageTitle={title} />

      {myPosition > 200 ? <BackToTopBtn /> : null}

      <div className="container pt-5">
        <div className="col-12 col-lg-10 mx-auto pb-4">
          <Link to="/blog" style={{ color: Colors.primary }}>
            <MdKeyboardBackspace size={40} /> Back to Blog
          </Link>
        </div>

        <p
          className="text-center"
          style={{ fontSize: "2.5em", fontWeight: "300" }}
        >
          {title}
        </p>
        <p className="text-secondary text-center" style={{ fontSize: "1em"}}>
            {description}
          </p>
      </div>
      <div className="container mt-3">
        <div className="col-12 col-lg-10 mx-auto">
          <p
            className="pt-0 mb-0 small text-center"
            style={{ color: Colors.accent2 }}
          >
            Published: {date}
          </p>
          <ShareButtonComp text={title} longtext={description} id={id} />
          <div className="d-flex flex-wrap justify-content-center">
            {tags.map(tag => (
              <span
                style={{
                  border: `1px solid ${Colors.accent2}`,
                  color: Colors.accent2,
                  borderRadius: "5px",
                }}
                className="small px-2 py-1 mr-1 mt-1"
                key={tag}
              >
                {tag}&nbsp;
              </span>
            ))}
          </div>

          <hr />

          <div
            className="gatsby-highlight-code-line"
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
          ></div>
          <ShareButtonComp text={title} longtext={description} id={id} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default BlogPost
